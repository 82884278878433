
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz,
        },
    })

    // *****************************************************************************************************************
    export default class DevUnity extends Vue {
        // Data function
        private data() {
            return {
                automaticMemoryManagementQuiz:
                    require("@/assets/quizzes/c-sharp-automatic-memory-management-quiz.json"),

                dotsQuiz:
                    require("@/assets/quizzes/unity-dots-quiz.json"),

                mathsQuiz:
                    require("@/assets/quizzes/unity-maths-quiz.json"),

                matricesQuiz:
                    require("@/assets/quizzes/unity-matrices-quiz.json"),

                miscellaneousQuiz:
                    require("@/assets/quizzes/unity-miscellaneous-quiz.json"),

                scriptingQuiz:
                    require("@/assets/quizzes/unity-scripting-quiz.json"),

                unityIconPathAndName:
                    require("@/assets/images/unity.png"),

                uiToolkitQuiz:
                    require("@/assets/quizzes/unity-ui-toolkit-quiz.json"),

                xrInteractionToolkitQuiz:
                    require("@/assets/quizzes/unity-xr-interaction-toolkit-quiz.json"),

                vectorsQuiz:
                    require("@/assets/quizzes/unity-vectors-quiz.json"),
            };
        }
    }
